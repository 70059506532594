<style src="./receipts-table-card.component.scss" lang="scss"></style>
<template>
  <div class="receipts-table-card">
    <div class="row">
      <div class="col-lg-3 col-12">
        <label class="v-label">{{ locale.phone }}</label>
        <v-select
            @select="changeSelectNumber"
            :value="selectNumber.id"
            :options="numbers"/>
      </div>
      <div class="col-12 d-lg-block d-none">
        <div class="local-table-card__table mt-40">
          <table-component id="table" v-if="data?.length!==0">
            <thead>
            <tr>
              <th class="text-center">{{ locale.receiptNumber }}</th>
              <th>{{ locale.date }}</th>
              <th>{{ locale.dialTheNumber }}</th>
              <th class="text-center">{{ locale.duration }}</th>
              <th class="text-center">{{ locale.amount }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!selectNumber">Nömrə seçin</tr>
            <tr v-for="(item, index) in data" :key="index">
              <th class="text-center">{{ item.number }}</th>
              <th>{{ item.date }}</th>
              <th>{{ item.dialTheNumber }}</th>
              <th class="text-center">{{ item.duration }}</th>
              <th class="text-center amount">{{ item.amount }}</th>
            </tr>
            </tbody>
          </table-component>
        </div>
      </div>
      <div class="col-12 d-lg-block d-none"   v-if="(data?.length !== 0) && userData.name">
        <div class="calls-table__info">
          <receipts-info-component :info="info"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./receipts-table-card.component.ts"></script>
