import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'receipts-info-component',
    props: {
        info: {
            type: Array
        }
    }
})

export default class ReceiptsInfoComponent extends Vue {

}