<style src="./receipts-paid-telephone-service.component.scss" lang="scss"></style>

<template>
  <div class="receipts-paid-telephone ">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
          <section>
            <customer-info-component :user="userData"/>
          </section>
          <section class="mt-30 mb-100">
            <v-card>
              <receipts-table-card-component
                  :info="generalInformationTotal"
                  :numbers="numbers"
                  :data="receipts"
                  @changeSelectNumber="changeSelectNumber"
                  v-if="numbers.length"
              />
              <not-data-component
                  class="mt-20"
                  v-if="(receipts?.length === 0 && numbers.length) && userData.name">
                Seçilmiş nömrə üzrə ödənilmiş qəbz məlumatları mövcud deyil
              </not-data-component>
              <not-data-component class="mt-20" v-if="(!numbers.length) && userData.name">
                Sizin adınıza aid telefon nömrəsi yoxdur
              </not-data-component>
            </v-card>
            <receipts-info-component class="my-20 d-lg-none d-block"
                                     :info="generalInformationTotal"/>
            <receipts-mobile-component :data="receipts"/>
            <v-pagination
                ref="pagination"
                :limit="pageSize"
                :dataCount="totalCountReceipts"
                :page="pageNumber"
                @changePage="changePage"
                class="mt-40"/>
            <not-data-component class="mt-20" v-if="(!numbers?.length) && !userData.name">
              Sizin adınıza aid telefon nömrəsi yoxdur
            </not-data-component>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./receipts-paid-telephone-service.component.ts"></script>
