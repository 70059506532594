import 'reflect-metadata';
import {mixins, Options} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import {IGetNumber} from '@/pages/local-talks-service/types/get-number';
import {IUser} from '@/core/auth/store/state';
import {IGetCallHistory} from '@/pages/local-talks-service/types/get-call-history';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {
    ReceiptsPaidTelephoneServiceSandbox
} from '@/pages/receipts-paid-telephone-service/sandbox/receipts-paid-telephone-service.sandbox';
import ReceiptsTableCardComponent
    from '@/pages/receipts-paid-telephone-service/components/receipts-table-card/receipts-table-card.component.vue';
import {IPayloadNumber} from '@/types/payload-number';
import ReceiptsMobileComponent
    from '@/pages/receipts-paid-telephone-service/components/receipts-mobile/receipts-mobile.component.vue';
import ReceiptsInfoComponent
    from '@/pages/receipts-paid-telephone-service/components/receipts-info/receipts-info.component.vue';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'local-talks-service-component',
    components: {
        CustomerInfoComponent,
        ReceiptsTableCardComponent,
        ServiceNameComponent,
        NotDataComponent, ReceiptsMobileComponent,
        ReceiptsInfoComponent

    }
})

export default class ReceiptsPaidTelephoneServiceComponent extends mixins(Router) {
    public service = 1151;
    public sandbox = container.resolve(ReceiptsPaidTelephoneServiceSandbox);
    private store = store;
    public pageNumber = 1;
    public pageSize = 10;
    public select = 0;
    public requestNumber = true;
    public $refs!: {
        pagination: {
            reset: () => void;
        },
    };

    get userData(): IUser {
        if (localStorage.getItem('mainToken')?.length && this.requestNumber) {
            this.getServiceData();
            this.requestNumber = false;
            let payload: IPayloadNumber = {
                token: String(localStorage.getItem('mainToken')),
                service: this.service
            };
            this.sandbox.getNumbers(payload);
        }
        return store.getters._getUserFullData;
    }

    get generalInformationTotal() {
        return [
            {
                name: 'Yekun müddət:',
                description: `${this.totalTerm} saniyə `
            },
            {
                name: 'Yekun məbləğ:',
                description: `${this.totalPayments} AZN`
            },
        ];
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get numbers(): IGetNumber[] {
        return this.store.getters._getNumbers;
    }

    get mainToken(): string | null {
        return localStorage.getItem('mainToken');
    }

    get receipts(): IGetCallHistory[] {
        return this.store.getters._getReceipts;
    }

    get totalCountReceipts(): number {
        return this.store.getters._getTotalCountReceipts;
    }

    get totalPayments(): number {
        return this.store.getters._getTotalPayments;
    }

    get totalTerm(): number {
        return this.store.getters._getTotalTerm;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    public resetPagination() {
        if (this.pageNumber > 1) {
            this.$refs.pagination.reset();
            this.pageNumber = 1;
        }
    }


    public changeSelectNumber(selectNumber: any): void {
        this.resetPagination();
        let payload = {
            token: localStorage.getItem('mainToken'),
            number: selectNumber,
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
        };
        this.sandbox.getReceipts(payload);
        this.select = selectNumber;
    }

    public changePage(info: any) {
        this.pageNumber = info.pageNumber;
        this.pageSize = info.pageSize;
        let payload = {
            token: localStorage.getItem('mainToken'),
            number: this.select,
            pageSize: info.pageSize,
            pageNumber: info.pageNumber,
        };
        this.sandbox.getReceipts(payload);
        window.scroll(0, this.$el.querySelector('#table').offsetTop);
    }
}
