<style src="./receipts-info.component.scss" lang="scss"> </style>

<template>
  <div class="receipts-info">
      <v-card dark class="total">
        <div class="total__wrapper" v-for="(item, index) in  info" :key="index">
          <div class="total__info-name">
            {{ item.name }}
          </div>
          <div class="total__info-description">
            {{ item.description }}
          </div>
        </div>
    </v-card>
  </div>
</template>

<script src="./receipts-info.component.ts"></script>