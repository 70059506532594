<style src="./receipts-mobile.component.scss" lang="scss"> </style>

<template>
  <div class="receipts-mobile mobile d-lg-none d-block">
    <div class="row">
      <div class="col-12">
        <accordion-component>
          <v-card class="mb-10" v-for="(item, index) in data" :key="index">
            <accordion-item-component>
              <template v-slot:title>
                <div class="information">
                  <div class="information__title">
                    {{ locale.dialTheNumber }}
                  </div>
                  <div class="information__description">
                    {{ item.dialTheNumber}}
                  </div>
                </div>
                <div class="information">
                  <div class="information__title">
                    {{ locale.amount }}
                  </div>
                  <div class="information__description">
                    {{ item.amount}}
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="information mt-20">
                  <div class="information__title">
                    {{ locale.date }}
                  </div>
                  <div class="information__description">
                    {{ item.date }}
                  </div>
                </div>
                <div class="information">
                  <div class="information__title">
                    {{ locale.duration }}
                  </div>
                  <div class="information__description">
                    {{ item.duration}}
                  </div>
                </div>
                <div class="information">
                  <div class="information__title">
                    {{locale.receiptNumber }}
                  </div>
                  <div class="information__description">
                    {{ item.number}}
                  </div>
                </div>
              </template>
            </accordion-item-component>
          </v-card>
        </accordion-component>
      </div>
    </div>
  </div>
</template>

<script src="./receipts-mobile.component.ts"></script>