import 'reflect-metadata';
import {locale} from '@/assets/db/db.conf.ts';
import {Options, Vue} from 'vue-class-component';
import AccordionComponent from '@/core/shared/accordion/accordion.component.vue';
import AccordionItemComponent from '@/core/shared/accordion-item/accordion-item.component.vue';

@Options({
    name: 'receipts-mobile-component',
    props: {
        data: {
            type: Array
        }
    },
    components: {
        AccordionComponent,
        AccordionItemComponent,
    }
})

export default class ReceiptsMobileComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.receipts) {
                this.locale = res.receipts;
            }
        });
    }

}